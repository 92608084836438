import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  getImage,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image';
import { ContentfulImageProp } from '@/types';

export const isAbsoluteUrl = (url: string): boolean =>
  url.startsWith(`http://`) || url.startsWith(`https://`);

export default {
  window: {
    breakpoint: {
      wide: 1920,
      large: 1440,
      desktop: 1200,
      tablet: 768,
      mobile: 425,
    },
  },
  contentfulDate: (_date, format = `MMM DD, YYYY`): string =>
    moment.utc(_date).format(format),
  slugToWord: (slug: string): string =>
    slug
      .split(`-`)
      .map((word: string) => {
        if (word === `and`) {
          return `&`;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(` `),
  stringToCamelCase: (string: string): string =>
    string.toLowerCase().replace(/(^|[^a-z0-9]+?)[a-z0-9]/gi, (match) => {
      // match.charAt(match.length - 1).toUpperCase(),
      if (match.length === 1) {
        // the 1st char
        return match.toLowerCase();
      }
      // char after special char
      return `-${match[1].toLowerCase()}`;
    }),
  camelToSnakeCase: (string: string): string =>
    string
      .split(/(?=[A-Z])/)
      .map((s) => s.toLowerCase())
      .join(`-`),
  getWindowInnerWidth: (): number => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
      const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener(`resize`, handleWindowSizeChange);
      handleWindowSizeChange();
      return () => window.removeEventListener(`resize`, handleWindowSizeChange);
    }, []);

    return width;
  },
  capitalizeFirstLetter: (str): string =>
    str.toLowerCase().charAt(0).toUpperCase() + str.slice(1),
  displayDifferentResolutions: function artDirection(
    imgs: ContentfulImageProp,
  ): IGatsbyImageData {
    // This processess gatsbyImageData to have a predefined dimension
    const breakpoints = Object.values(this.window.breakpoint);
    const widthBasedImages = [];
    let defaultImage = null;

    Object.entries(imgs).forEach(([key, value], index) => {
      const threshold =
        Object.entries(imgs).length - 1 === index ? `max` : `min`;

      if (key === `wide`) {
        defaultImage = value;
      }

      const item = {
        media: `(${threshold}-width: ${breakpoints[index]}px)`,
        image: getImage(value),
      };
      widthBasedImages.push(item);
    });

    const newImages = withArtDirection(
      getImage(defaultImage),
      widthBasedImages,
    );
    return newImages;
  },
};
